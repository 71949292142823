import { gql } from '@apollo/client/core'

export const getFileKeysQuery = gql`
  query getFileKeys($prefix: String!) {
    getFileKeys(prefix: $prefix)
  }
`

export const getFileKeyQuery = gql`
  query getFileKey($prefix: String!) {
    getFileKey(prefix: $prefix)
  }
`

export const getPreSignedPostMutation = gql`
  mutation getPreSignedPost($key: String!) {
    getPreSignedPost(key: $key) {
      url
      fields
      __typename
    }
  }
`

export const deleteFileMutation = gql`
  mutation deleteFile($key: String!) {
    deleteFile(key: $key) {
      success
      __typename
    }
  }
`
