import { client, accessToken } from '@/apollo-client/ApolloClients'

import {
  createContractorRating as createContractorRatingFn,
  createClientRating as createClientRatingFn,
  updateContractorRating as updateContractorRatingFn,
  updateClientRating as updateClientRatingFn,
  deleteRating as deleteRatingFn,
  getRatingById as getRatingByIdFn,
  getRatingsByQuoteId as getRatingsByQuoteIdFn,
  getRatingsByCreatorId as getRatingsByCreatorIdFn,
  getRatingsByContractorId as getRatingsByContractorIdFn,
  getRatingsByClientId as getRatingsByClientIdFn,
  searchRatings as searchRatingsFn
} from '@/apollo-client/requests/rating'

export const FIND_BY = Object.freeze({
  QUOTE_ID: 'quoteId',
  CREATOR_ID: 'creatorId',
  CONTRACTOR_ID: 'contractorId',
  CLIENT_ID: 'clientId'
})

export async function createContractorRating({ rating }) {
  return createContractorRatingFn({
    client: client(),
    accessToken: accessToken(),
    rating
  })
}

export async function createClientRating({ rating }) {
  return createClientRatingFn({
    client: client(),
    accessToken: accessToken(),
    rating
  })
}

export async function updateContractorRating({ ratingId, changes }) {
  return updateContractorRatingFn({
    client: client(),
    accessToken: accessToken(),
    ratingId,
    changes
  })
}

export async function updateClientRating({ ratingId, changes }) {
  return updateClientRatingFn({
    client: client(),
    accessToken: accessToken(),
    ratingId,
    changes
  })
}

export async function deleteRating({ ratingId }) {
  return deleteRatingFn({
    client: client(),
    accessToken: accessToken(),
    ratingId
  })
}

export async function getRatingById({ ratingId }) {
  return getRatingByIdFn({
    client: client(),
    accessToken: accessToken(),
    ratingId
  })
}

export async function getRatingsByType({ input, type = FIND_BY.CLIENT_ID }) {
  switch (type) {
    case FIND_BY.CLIENT_ID: {
      return await getRatingsByClientId({ input })
    }
    case FIND_BY.CONTRACTOR_ID: {
      return await getRatingsByContractorId({ input })
    }
    case FIND_BY.CREATOR_ID: {
      return await getRatingsByCreatorId({ input })
    }
    case FIND_BY.QUOTE_ID: {
      return await getRatingsByQuoteId({ input })
    }
    default:
      throw new Error(`Unable to call getRatingsByType as type: [${type}] is not implemented.`)
  }
}

export async function getRatingsByQuoteId({ input }) {
  return getRatingsByQuoteIdFn({
    client: client(),
    accessToken: accessToken(),
    input
  })
}

export async function getRatingsByCreatorId({ input }) {
  return getRatingsByCreatorIdFn({
    client: client(),
    accessToken: accessToken(),
    input
  })
}

export async function getRatingsByContractorId({ input }) {
  return getRatingsByContractorIdFn({
    client: client(),
    accessToken: accessToken(),
    input
  })
}

export async function getRatingsByClientId({ input }) {
  return getRatingsByClientIdFn({
    client: client(),
    accessToken: accessToken(),
    input
  })
}

export async function searchRatings({ input }) {
  return searchRatingsFn({
    client: client(),
    accessToken: accessToken(),
    input
  })
}
