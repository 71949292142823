import { clientIsRequired } from './constants'

import {
  getFileKeysQuery,
  getFileKeyQuery,
  getPreSignedPostMutation,
  deleteFileMutation
} from '@/apollo-client/api-calls/bolsterApprovals'

const handleError = async (asyncFn) => {
  try {
    return await asyncFn()
  } catch (error) {
    console.error('Detailed error: ', JSON.stringify(error, null, 2))
    return null
  }
}

export async function getFileKeys({ accessToken, client, prefix }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.query({
      query: getFileKeysQuery,
      variables: {
        accessToken,
        prefix
      }
    })

    return data.getFileKeys
  })
}

export async function getFileKey({ accessToken, client, prefix }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.query({
      query: getFileKeyQuery,
      variables: {
        accessToken,
        prefix
      }
    })

    return data.getFileKey
  })
}

export async function getPreSignedPost({ accessToken, client, key }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.mutate({
      mutation: getPreSignedPostMutation,
      variables: {
        accessToken,
        key
      }
    })

    return data.getPreSignedPost
  })
}

export async function deleteFile({ accessToken, client, key }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.mutate({
      mutation: deleteFileMutation,
      variables: {
        accessToken,
        key
      }
    })

    return data.deleteFile
  })
}
