import { client } from '../apollo-config'
import {
  createApprovalMutation,
  getApprovalsByApproverQuery,
  processApprovalActionQuery,
  getApprovalsQuery,
  addApprovalStepQuery,
  addApproverToApprovalStepQuery,
  getApprovalsByItemAndApproverQuery,
  processMultipleApprovalActionsQuery,
  getQuotesAwaitingPaymentStatesFromApprovalsQuery
} from '../api-calls/approval'

const handleError = async (asyncFn) => {
  try {
    return await asyncFn()
  } catch (error) {
    console.log('error: ', error)
    return null
  }
}

export async function createApproval({ approval, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.mutate({
      mutation: createApprovalMutation,
      variables: {
        approval,
        accessToken
      }
    })
    return data.createApproval
  })
}

export async function getApprovalsByItem({ itemId, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.query({
      query: getApprovalsQuery,
      variables: {
        itemId,
        accessToken
      }
    })
    return data.getApprovalsByItem
  })
}

export async function getApprovalsByItemAndApprover({ userId, itemId, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.query({
      query: getApprovalsByItemAndApproverQuery,
      variables: {
        itemId,
        userId,
        accessToken
      }
    })
    return data.getApprovalsByItemAndApprover
  })
}

export async function getApprovalsByApprover({ quoteId, userId, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.query({
      query: getApprovalsByApproverQuery,
      variables: {
        userId,
        quoteId,
        accessToken
      }
    })
    return data.getApprovalsByApprover
  })
}

export async function getQuotesAwaitingPaymentStatesFromApprovals({
  quoteIds,
  userId,
  accessToken
}) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.query({
      query: getQuotesAwaitingPaymentStatesFromApprovalsQuery,
      variables: {
        userId,
        quoteIds,
        accessToken
      }
    })
    return data.getQuotesAwaitingPaymentStatesFromApprovals
  })
}

export async function processApprovalAction({
  approvalId,
  approvalStepId,
  approverId,
  status,
  accessToken
}) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.mutate({
      mutation: processApprovalActionQuery,
      variables: {
        input: {
          approvalId,
          approvalStepId,
          approverId,
          status
        },
        accessToken
      }
    })
    return data.processApprovalAction
  })
}

export async function processMultipleApprovalActions({ input, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.mutate({
      mutation: processMultipleApprovalActionsQuery,
      variables: {
        input,
        accessToken
      }
    })
    return data.processMultipleApprovalActions
  })
}

export async function addApprovalStep({ approvalId, approvalStep, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.mutate({
      mutation: addApprovalStepQuery,
      variables: {
        approvalStep,
        approvalId,
        accessToken
      }
    })
    return data.addApprovalStep
  })
}

export async function addApproverToApprovalStep({
  approvalId,
  approvalStepId,
  approver,
  accessToken
}) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.mutate({
      mutation: addApproverToApprovalStepQuery,
      variables: {
        approver,
        approvalId,
        approvalStepId,
        accessToken
      }
    })
    return data.addApproverToApprovalStep
  })
}
