import { gql } from '@apollo/client/core'

export const postPushNotificationSubscription = gql`
  mutation PostPushNotificationSubscription($input: PushNotificationSubscriptionInput!) {
    postPushNotificationSubscription(input: $input) {
      user_id
      topic
    }
  }
`
