import { gql } from '@apollo/client/core'

const ApprovalFields = gql`
  fragment ApprovalFields on Approval {
    id
    quoteId
    itemId
    nextStepRole
    nextStepStatus
    approvalSteps {
      id
      order
      status
      approvers {
        id
        userId
        role
        authority
      }
      timeApproved
      timeCreated
      timeUpdated
      timeDeleted
    }
    history {
      id
      approverId
      approvalStepId
      approvalLogStatus
      timeApproved
      timeUpdated
      timeCreated
      oAuthorization
    }
    files {
      file_name
      file_type
      id
      file_url
    }
    timeApproved
    timeCreated
    timeUpdated
    timeDeleted
  }
`

export const createApprovalMutation = gql`
  ${ApprovalFields}
  mutation createApproval($approval: CreateApprovalInput!) {
    createApproval(approval: $approval) {
      ...ApprovalFields
    }
  }
`

export const getApprovalsQuery = gql`
  ${ApprovalFields}
  query getApprovalsByItem($itemId: ID!) {
    getApprovalsByItem(itemId: $itemId) {
      ...ApprovalFields
    }
  }
`

export const getApprovalsByItemAndApproverQuery = gql`
  ${ApprovalFields}
  query getApprovalsByItemAndApprover($itemId: ID!, $userId: ID!) {
    getApprovalsByItemAndApprover(itemId: $itemId, userId: $userId) {
      ...ApprovalFields
    }
  }
`

export const getApprovalsByApproverQuery = gql`
  ${ApprovalFields}
  query getApprovalsByApprover($quoteId: ID!, $userId: ID!) {
    getApprovalsByApprover(quoteId: $quoteId, userId: $userId) {
      ...ApprovalFields
    }
  }
`

export const getQuotesAwaitingPaymentStatesFromApprovalsQuery = gql`
  query getQuotesAwaitingPaymentStatesFromApprovals($quoteIds: [ID!]!, $userId: ID!) {
    getQuotesAwaitingPaymentStatesFromApprovals(quoteIds: $quoteIds, userId: $userId) {
      quoteId
      state
      oldestUnpaidItem
    }
  }
`

export const processApprovalActionQuery = gql`
  ${ApprovalFields}
  mutation processApprovalAction($input: ProcessApprovalActionInput!) {
    processApprovalAction(input: $input) {
      ...ApprovalFields
    }
  }
`

export const processMultipleApprovalActionsQuery = gql`
  ${ApprovalFields}
  mutation processMultipleApprovalActions($input: [ProcessApprovalActionInput!]!) {
    processMultipleApprovalActions(input: $input) {
      ...ApprovalFields
    }
  }
`

export const addApprovalStepQuery = gql`
  ${ApprovalFields}
  mutation addApprovalStep($approvalId: ID!, $approvalStep: ApprovalStepInput!) {
    addApprovalStep(approvalId: $approvalId, approvalStep: $approvalStep) {
      ...ApprovalFields
    }
  }
`

export const addApproverToApprovalStepQuery = gql`
  ${ApprovalFields}
  mutation addApproverToApprovalStep(
    $approvalId: ID!
    $approvalStepId: ID!
    $approver: ApproverInput!
  ) {
    addApproverToApprovalStep(
      approvalId: $approvalId
      approvalStepId: $approvalStepId
      approver: $approver
    ) {
      ...ApprovalFields
    }
  }
`

export const getThreeWayApprovalsFilePreSignedPost = gql`
  mutation getThreeWayApprovalsFilePreSignedPost($input: PreSignedPostInput!) {
    getThreeWayApprovalsFilePreSignedPost(input: $input)
  }
`

export const saveApprovalItemFiles = gql`
  ${ApprovalFields}
  mutation saveApprovalItemFiles($input: SaveFilesInput!) {
    saveApprovalItemFiles(input: $input) {
      ...ApprovalFields
    }
  }
`
