import { gql } from '@apollo/client/core'

const ItemReviewalFields = gql`
  fragment ItemReviewalFields on ItemReviewal {
    id
    itemId
    changeOrderId
    reviewedBy
    isReviewed
    timeReviewed
    timeCreated
    timeUpdated
    timeDeleted
  }
`

export const upsertItemReviewalMutation = gql`
  ${ItemReviewalFields}
  mutation upsertItemReviewal($itemReviewal: UpsertItemReviewalInput!) {
    upsertItemReviewal(itemReviewal: $itemReviewal) {
      ...ItemReviewalFields
    }
  }
`

export const getItemReviewalQuery = gql`
  ${ItemReviewalFields}
  query getItemReviewal($itemId: ID!, $changeOrderId: ID!) {
    getItemReviewal(itemId: $itemId, changeOrderId: $changeOrderId) {
      ...ItemReviewalFields
    }
  }
`

export const getItemReviewalsByChangeOrderQuery = gql`
  ${ItemReviewalFields}
  query getItemReviewalsByChangeOrder($changeOrderId: ID!) {
    getItemReviewalsByChangeOrder(changeOrderId: $changeOrderId) {
      ...ItemReviewalFields
    }
  }
`
