import { gql } from '@apollo/client/core'

const ClientRatingFields = gql`
  fragment ClientRatingFields on ClientRating {
    ratingId
    type
    companyId
    creatorId
    timeCreated
    timeUpdated
    timeDeleted
    __typename
    quoteId
    clientId
    overallRating
    description
  }
`

const ContractorRatingFields = gql`
  fragment ContractorRatingFields on ContractorRating {
    ratingId
    type
    companyId
    creatorId
    timeCreated
    timeUpdated
    timeDeleted
    __typename
    quoteId
    contractorId
    overallRating
    description
  }
`

export const createContractorRatingMutation = gql`
  ${ContractorRatingFields}
  mutation createContractorRatingMutation($rating: ContractorRatingInput!) {
    createContractorRating(rating: $rating) {
      ... on ContractorRating {
        ...ContractorRatingFields
      }
    }
  }
`

export const createClientRatingMutation = gql`
  ${ClientRatingFields}
  mutation createClientRatingMutation($rating: ClientRatingInput!) {
    createClientRating(rating: $rating) {
      ... on ClientRating {
        ...ClientRatingFields
      }
    }
  }
`

export const updateContractorRatingMutation = gql`
  ${ContractorRatingFields}
  mutation updateContractorRatingMutation($ratingId: ID!, $changes: UpdateRatingInput!) {
    updateContractorRating(ratingId: $ratingId, changes: $changes) {
      ... on ContractorRating {
        ...ContractorRatingFields
      }
    }
  }
`

export const updateClientRatingMutation = gql`
  ${ClientRatingFields}
  mutation updateClientRatingMutation($ratingId: ID!, $changes: UpdateRatingInput!) {
    updateClientRating(ratingId: $ratingId, changes: $changes) {
      ... on ClientRating {
        ...ClientRatingFields
      }
    }
  }
`

export const deleteRatingMutation = gql`
  ${ClientRatingFields}
  ${ContractorRatingFields}
  mutation deleteRatingMutation($ratingId: ID!) {
    deleteRating(ratingId: $ratingId) {
      ... on ClientRating {
        ...ClientRatingFields
      }
      ... on ContractorRating {
        ...ContractorRatingFields
      }
    }
  }
`

export const getRatingByIdQuery = gql`
  ${ClientRatingFields}
  ${ContractorRatingFields}
  query getRatingById($ratingId: ID!) {
    getRatingById(ratingId: $ratingId) {
      ... on ClientRating {
        ...ClientRatingFields
      }
      ... on ContractorRating {
        ...ContractorRatingFields
      }
    }
  }
`

export const getRatingsByCompanyIdQuery = gql`
  ${ClientRatingFields}
  ${ContractorRatingFields}
  query getRatingsByCompanyId($input: RatingQueryInput!) {
    getRatingsByCompanyId(input: $input) {
      items {
        ... on ClientRating {
          ...ClientRatingFields
        }
        ... on ContractorRating {
          ...ContractorRatingFields
        }
      }
      nextToken
    }
  }
`

export const getContractorRatingsByCompanyIdQuery = gql`
  ${ContractorRatingFields}
  query getContractorRatingsByCompanyId($input: RatingQueryInput!) {
    getContractorRatingsByCompanyId(input: $input) {
      items {
        ... on ContractorRating {
          ...ContractorRatingFields
        }
      }
      nextToken
    }
  }
`

export const getClientRatingsByCompanyIdQuery = gql`
  ${ClientRatingFields}
  query getClientRatingsByCompanyId($input: RatingQueryInput!) {
    getClientRatingsByCompanyId(input: $input) {
      items {
        ... on ClientRating {
          ...ClientRatingFields
        }
      }
      nextToken
    }
  }
`

export const getRatingsByQuoteIdQuery = gql`
  ${ClientRatingFields}
  ${ContractorRatingFields}
  query getRatingsByQuoteId($input: RatingQueryInput!) {
    getRatingsByQuoteId(input: $input) {
      items {
        ... on ClientRating {
          ...ClientRatingFields
        }
        ... on ContractorRating {
          ...ContractorRatingFields
        }
      }
      nextToken
    }
  }
`

export const getRatingsByCreatorIdQuery = gql`
  ${ClientRatingFields}
  ${ContractorRatingFields}
  query getRatingsByCreatorId($input: RatingQueryInput!) {
    getRatingsByCreatorId(input: $input) {
      items {
        ... on ClientRating {
          ...ClientRatingFields
        }
        ... on ContractorRating {
          ...ContractorRatingFields
        }
      }
      nextToken
    }
  }
`

export const getRatingsByContractorIdQuery = gql`
  ${ContractorRatingFields}
  query getRatingsByContractorId($input: RatingQueryInput!) {
    getRatingsByContractorId(input: $input) {
      items {
        ... on ContractorRating {
          ...ContractorRatingFields
        }
      }
      nextToken
    }
  }
`

export const getRatingsByClientIdQuery = gql`
  ${ClientRatingFields}
  query getRatingsByClientId($input: RatingQueryInput!) {
    getRatingsByClientId(input: $input) {
      items {
        ... on ClientRating {
          ...ClientRatingFields
        }
      }
      nextToken
    }
  }
`

export const searchRatingsQuery = gql`
  ${ContractorRatingFields}
  ${ClientRatingFields}
  query searchRatings($input: RatingSearchInput!) {
    searchRatings(input: $input) {
      nodes {
        ... on ClientRating {
          ...ClientRatingFields
        }
        ... on ContractorRating {
          ...ContractorRatingFields
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
`
