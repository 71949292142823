import { gql } from '@apollo/client/core'

export const quoteMessagesQuery = gql`
  query QuoteMessagesQuery($refId: String!, $lastEvaluatedKey: LastEvaluatedKeyInput) {
    getMessages(refId: $refId, lastEvaluatedKey: $lastEvaluatedKey) {
      lastEvaluatedKey {
        createdAt
        messageId
        refId
      }
      messageList {
        body
        messageId
        createdAt
        userId
        refId
      }
    }
  }
`

export const chatSummaryQuery = gql`
  query ChatSummaryQuery($userId: String!, $companyId: String!) {
    getChatSummary(userId: $userId, companyId: $companyId) {
      refId
      chatName
      contractorId
      companyId
      lastTimeModified
      chatUsers {
        lastTimeActive
        refId
        userId
        userName
      }
      messages {
        lastEvaluatedKey {
          createdAt
          messageId
          refId
        }
        messageList {
          body
          createdAt
          messageId
          refId
          userId
        }
      }
    }
  }
`

export const getChatQuery = gql`
  query GetChatQuery($refId: String!) {
    getChat(refId: $refId) {
      refId
      chatName
      contractorId
      companyId
      lastTimeModified
      chatUsers {
        lastTimeActive
        refId
        userId
        userName
      }
      messages {
        lastEvaluatedKey {
          createdAt
          messageId
          refId
        }
        messageList {
          createdAt
          body
          messageId
          userId
          refId
        }
      }
    }
  }
`

export const createMessagesMutation = gql`
  mutation CreateMessageMutation($refId: String!, $userId: String!, $body: String!) {
    createMessage(refId: $refId, userId: $userId, body: $body) {
      body
      createdAt
      messageId
      refId
      userId
    }
  }
`

export const createChatMutation = gql`
  mutation CreateChatMutation(
    $refId: String!
    $chatName: String!
    $contractorId: String!
    $companyId: String!
    $userId: String!
    $userName: String!
  ) {
    createChat(
      refId: $refId
      chatName: $chatName
      userId: $userId
      contractorId: $contractorId
      companyId: $companyId
      userName: $userName
    ) {
      refId
      chatName
      contractorId
      companyId
      lastTimeModified
      chatUsers {
        lastTimeActive
        refId
        userId
        userName
      }
      messages {
        lastEvaluatedKey {
          createdAt
          messageId
          refId
        }
        messageList {
          createdAt
          body
          messageId
          userId
          refId
        }
      }
    }
  }
`

export const onCreateMessagesSubscription = gql`
  subscription OnCreateMessageSubscription($filter: String) {
    onCreateMessage(filter: $filter) {
      body
      createdAt
      messageId
      refId
      userId
    }
  }
`

export const onCreateChatSubscription = gql`
  subscription OnCreateChatSubscription($contractorId: String!) {
    onCreateChat(contractorId: $contractorId) {
      refId
      chatName
      contractorId
      companyId
      lastTimeModified
      chatUsers {
        lastTimeActive
        refId
        userId
        userName
      }
      messages {
        lastEvaluatedKey {
          createdAt
          messageId
          refId
        }
        messageList {
          createdAt
          body
          messageId
          userId
          refId
        }
      }
    }
  }
`

export const updateLastTimeActiveMutation = gql`
  mutation UpdateLastTimeActive($refId: String!, $userId: String!) {
    updateLastTimeActive(refId: $refId, userId: $userId)
  }
`

export const addUserToChatMutation = gql`
  mutation AddUserToChat($refId: String!, $userId: String!, $userName: String!) {
    addUserToChat(refId: $refId, userId: $userId, userName: $userName) {
      refId
      userId
      lastTimeActive
      userName
    }
  }
`
