import { client, accessToken } from '../apollo-config'

import {
  getFileKey as getFileKeyFn,
  getFileKeys as getFileKeysFn,
  getPreSignedPost as getPreSignedPostFn,
  deleteFile as deleteFileFn
} from '../requests/bolsterApprovals'

export async function getFileKeys({ prefix }) {
  return getFileKeysFn({
    client: client(),
    accessToken: accessToken(),
    prefix
  })
}

export async function getFileKey({ prefix }) {
  return getFileKeyFn({
    client: client(),
    accessToken: accessToken(),
    prefix
  })
}

export async function getPreSignedPost({ key }) {
  const res = await getPreSignedPostFn({
    client: client(),
    accessToken: accessToken(),
    key
  })

  return {
    url: res.url,
    fields: JSON.parse(res.fields)
  }
}

export async function deleteFile({ key }) {
  return deleteFileFn({
    client: client(),
    accessToken: accessToken(),
    key
  })
}
