import { clientIsRequired } from './constants'
import {
  createContractorRatingMutation,
  createClientRatingMutation,
  updateContractorRatingMutation,
  updateClientRatingMutation,
  deleteRatingMutation,
  getRatingByIdQuery,
  getRatingsByCompanyIdQuery,
  getContractorRatingsByCompanyIdQuery,
  getClientRatingsByCompanyIdQuery,
  getRatingsByQuoteIdQuery,
  getRatingsByCreatorIdQuery,
  getRatingsByContractorIdQuery,
  getRatingsByClientIdQuery,
  searchRatingsQuery
} from '../api-calls/rating'

const handleError = async (asyncFn) => {
  try {
    return await asyncFn()
  } catch (error) {
    console.log('error: ', error)
    return null
  }
}

export async function createContractorRating({ rating, client }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.mutate({
      mutation: createContractorRatingMutation,
      variables: {
        rating
      }
    })

    return data.createContractorRating
  })
}

export async function createClientRating({ rating, client }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.mutate({
      mutation: createClientRatingMutation,
      variables: {
        rating
      }
    })

    return data.createClientRating
  })
}

export async function updateContractorRating({ ratingId, changes, client }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.mutate({
      mutation: updateContractorRatingMutation,
      variables: {
        ratingId,
        changes
      }
    })

    return data.updateContractorRating
  })
}

export async function updateClientRating({ ratingId, changes, client }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.mutate({
      mutation: updateClientRatingMutation,
      variables: {
        ratingId,
        changes
      }
    })

    return data.updateClientRating
  })
}

export async function deleteRating({ ratingId, client }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.mutate({
      mutation: deleteRatingMutation,
      variables: {
        ratingId
      }
    })

    return data.deleteRating
  })
}

export async function getRatingById({ ratingId, client }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.query({
      query: getRatingByIdQuery,
      variables: {
        ratingId
      },
      fetchPolicy: 'no-cache'
    })

    return data.getRatingById
  })
}

export async function getRatingsByCompanyId({ input, client }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.query({
      query: getRatingsByCompanyIdQuery,
      variables: {
        input
      },
      fetchPolicy: 'no-cache'
    })

    return data.getRatingsByCompanyId
  })
}

export async function getContractorRatingsByCompanyId({ input, client }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.query({
      query: getContractorRatingsByCompanyIdQuery,
      variables: {
        input
      },
      fetchPolicy: 'no-cache'
    })

    return data.getContractorRatingsByCompanyId
  })
}

export async function getClientRatingsByCompanyId({ input, client }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.query({
      query: getClientRatingsByCompanyIdQuery,
      variables: {
        input
      },
      fetchPolicy: 'no-cache'
    })

    return data.getClientRatingsByCompanyId
  })
}

export async function getRatingsByQuoteId({ input, client }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.query({
      query: getRatingsByQuoteIdQuery,
      variables: {
        input
      },
      fetchPolicy: 'no-cache'
    })

    return data.getRatingsByQuoteId
  })
}

export async function getRatingsByCreatorId({ input, client }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.query({
      query: getRatingsByCreatorIdQuery,
      variables: {
        input
      },
      fetchPolicy: 'no-cache'
    })

    return data.getRatingsByCreatorId
  })
}

export async function getRatingsByContractorId({ input, client }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.query({
      query: getRatingsByContractorIdQuery,
      variables: {
        input
      },
      fetchPolicy: 'no-cache'
    })

    return data.getRatingsByContractorId
  })
}

export async function getRatingsByClientId({ input, client }) {
  clientIsRequired(client)

  // eslint-disable-next-line no-unused-vars
  const { filters, ...rest } = input

  return handleError(async () => {
    const { data } = await client.query({
      query: getRatingsByClientIdQuery,
      variables: {
        input: rest
      },
      fetchPolicy: 'no-cache'
    })

    return data.getRatingsByClientId
  })
}

export async function searchRatings({ input, client }) {
  clientIsRequired(client)

  return handleError(async () => {
    const { data } = await client.query({
      query: searchRatingsQuery,
      variables: {
        input: input
      },
      fetchPolicy: 'no-cache'
    })

    return data.searchRatings
  })
}
