function clientIsRequired(client) {
  if (!client) {
    throw new Error('client is required')
  }

  if (typeof client !== 'object') {
    throw new Error(`Parameter must be of type object, got ${typeof client}`)
  }
}

function apiKeyClientIsRequired(apiKeyClient) {
  if (!apiKeyClient) {
    throw new Error('apiKeyClient is required')
  }

  if (typeof apiKeyClient !== 'object') {
    throw new Error(`Parameter must be of type object, got ${typeof apiKeyClient}`)
  }
}

export { apiKeyClientIsRequired, clientIsRequired }
