import { gql } from '@apollo/client/core'

export const getActivity = gql`
  query getActivity($activityId: ID!) {
    getActivityById(activityId: $activityId) {
      items {
        activity_id
        activity_status
        activity_notification_heading
        activity_creator
        activity_owner
        activity_type_id
        activity_base_type
        activity_type
        activity_time_created
        activity_escalation_owner
        activity_desc
        company_id
        file_ids
        quote_id
        target_id
        target_type
        oTarget
        oOwner
        oCreator
        activity_mentions {
          mention_type
          mention_type_id
          mention_text
          mention_start_index
        }
      }
      nextToken
    }
  }
`

export const getActivitiesByTarget = gql`
  query getUserActivities($input: ActivityQueryInput) {
    getActivitiesByTarget(input: $input) {
      items {
        activity_id
        activity_status
        activity_notification_heading
        activity_creator
        activity_owner
        activity_type_id
        activity_base_type
        activity_type
        activity_time_created
        activity_escalation_owner
        activity_desc
        company_id
        file_ids
        quote_id
        target_id
        target_type
        oTarget
        oOwner
        oCreator
        activity_mentions {
          mention_type
          mention_type_id
          mention_text
          mention_start_index
        }
      }
      nextToken
    }
  }
`

export const getActivitiesByCreator = gql`
  query getActivitiesByCreator($input: ActivityQueryInput) {
    getActivitiesByCreator(input: $input) {
      items {
        activity_id
        activity_status
        activity_notification_heading
        activity_creator
        activity_owner
        activity_type_id
        activity_base_type
        activity_type
        activity_time_created
        activity_escalation_owner
        activity_desc
        company_id
        file_ids
        quote_id
        target_id
        target_type
        oTarget
        oOwner
        oCreator
        activity_mentions {
          mention_type
          mention_type_id
          mention_text
          mention_start_index
        }
      }
      nextToken
    }
  }
`

export const getActivitiesByQuote = gql`
  query getActivitiesByQuote($input: ActivityQueryInput) {
    getActivitiesByQuote(input: $input) {
      items {
        activity_id
        activity_status
        activity_notification_heading
        activity_creator
        activity_owner
        activity_type_id
        activity_base_type
        activity_type
        activity_time_created
        activity_escalation_owner
        activity_desc
        company_id
        file_ids
        quote_id
        target_id
        target_type
        oTarget
        oOwner
        oCreator
        activity_mentions {
          mention_type
          mention_type_id
          mention_text
          mention_start_index
        }
      }
      nextToken
    }
  }
`

export const getChannelActivities = gql`
  query getChannelActivities(
    $input: ChannelActivityQueryByChannelInput!
    $userInput: MemberViewedQuery!
  ) {
    getChannelActivities(input: $input) {
      items {
        channel_activity_id
        channel_id
        activity_id
        activity_created_at
        has_member_viewed(input: $userInput)
        activity {
          activity_id
          activity_base_type
          activity_commented
          activity_completion_desc
          activity_count_comments
          activity_count_likes
          activity_count_pins
          activity_creator
          activity_desc
          activity_escalation_owner
          activity_is_hidden
          activity_is_version
          activity_notification_heading
          activity_owner
          activity_shared
          activity_status
          activity_time_completed
          activity_time_created
          activity_time_due
          activity_type
          activity_type_id
          company_id
          file_ids
          parent_activity_id
          parent_activity {
            activity_id
            activity_creator
            oCreator
            activity_notification_heading
            activity_time_created
            file_ids
          }
          quote_id
          target_id
          target_type
          oTarget
          oOwner
          oCreator
          activity_mentions {
            mention_type
            mention_type_id
            mention_text
            mention_start_index
          }
        }
      }
      nextToken
    }
  }
`

export const getNotificationsByUser = gql`
  query getNotificationsByUser($input: NotificationQueryByUserInput!) {
    getNotificationsByUser(input: $input) {
      items {
        channel_id
        activity_id
        user_id
        member_id
        activity_created_at
        activity {
          activity_id
          activity_creator
          activity_completion_desc
          activity_owner
          activity_notification_heading
          target_id
          target_type
          activity_time_created
          oCreator
          activity_mentions {
            mention_type
            mention_type_id
            mention_text
            mention_start_index
          }
        }
        channel {
          channel_id
          channel_name
          channel_type
          channel_type_id
          created_at
          company_id
          parent_channel_id
          client_user_id
          client_fname
          client_lname
          channel_status
          channel_members {
            items {
              user_id
              user_fname
              user_lname
              created_at
              member_id
            }
          }
          latest_channel_activity {
            channel_activity_id
            activity_created_at
            activity {
              activity_id
              activity_creator
              activity_time_created
              activity_completion_desc
              activity_owner
              activity_notification_heading
              target_id
              target_type
            }
          }
        }
      }
      nextToken
    }
  }
`

export const removeNotification = gql`
  mutation removeNotification($input: NotificationRemoveMutationInput!) {
    removeNotification(input: $input) {
      member_id
      user_id
      activity_id
      channel_id
    }
  }
`

export const postActivity = gql`
  mutation postActivity($activity: ActivityInput!) {
    postActivity(activity: $activity) {
      activity_id
      activity_status
      activity_notification_heading
      activity_creator
      activity_owner
      activity_type_id
      activity_base_type
      activity_type
      activity_time_created
      activity_escalation_owner
      activity_desc
      company_id
      file_ids
      quote_id
      target_id
      target_type
      oTarget
      oOwner
      oCreator
      activity_mentions {
        mention_type
        mention_type_id
        mention_text
        mention_start_index
      }
    }
  }
`

export const updateActivity = gql`
  mutation updateActivity($activityId: ID!, $activity: ActivityInput!) {
    updateActivity(activityId: $activityId, input: $activity) {
      activity_id
      activity_status
      activity_notification_heading
      activity_creator
      activity_owner
      activity_type_id
      activity_base_type
      activity_type
      activity_time_created
      activity_escalation_owner
      activity_desc
      company_id
      file_ids
      quote_id
      target_id
      target_type
      oTarget
      oOwner
      oCreator
      activity_mentions {
        mention_type
        mention_type_id
        mention_text
        mention_start_index
      }
    }
  }
`

export const removeActivity = gql`
  mutation removeActivity($activityId: ID!, $channelId: ID!) {
    removeActivity(activityId: $activityId, channelId: $channelId) {
      activity_id
      activity_status
      activity_notification_heading
      activity_creator
      activity_owner
      activity_type_id
      activity_base_type
      activity_type
      activity_time_created
      activity_escalation_owner
      activity_desc
      company_id
      file_ids
      quote_id
      target_id
      target_type
      oTarget
      oOwner
      oCreator
      activity_mentions {
        mention_type
        mention_type_id
        mention_text
        mention_start_index
      }
    }
  }
`

export const postChannelUserOnline = gql`
  mutation postChannelUserOnline($input: ChannelUserOnlineMutationInput!) {
    postChannelUserOnline(input: $input) {
      user_id
      channel_id
      expire_at
    }
  }
`

export const removeChannelUserOnline = gql`
  mutation removeChannelUserOnline($input: ChannelUserOnlineMutationInput!) {
    removeChannelUserOnline(input: $input) {
      user_id
      channel_id
      expire_at
    }
  }
`

export const postMemberActivity = gql`
  mutation postMemberActivity($input: MemberActivityMutationInput!) {
    postMemberActivity(input: $input) {
      user_id
      activity_id
      time_seen
    }
  }
`

export const getFilePreSignedPost = gql`
  query getFilePreSignedPost($input: PreSignedPostInput!) {
    getFilePreSignedPost(input: $input)
  }
`

export const subscribeToNotifications = gql`
  subscription onSavedNotification($userId: ID!) {
    onSavedNotification(user_id: $userId) {
      user_id
      activity_id
      channel_id
      member_id
      activity_created_at
      activity {
        activity_id
        activity_creator
        activity_completion_desc
        activity_owner
        activity_notification_heading
        target_id
        target_type
        oCreator
        activity_time_created
        activity_mentions {
          mention_type
          mention_type_id
          mention_text
          mention_start_index
        }
      }
      channel {
        channel_id
        channel_name
        channel_type
        channel_type_id
        created_at
        company_id
        parent_channel_id
        client_user_id
        client_fname
        client_lname
        channel_status
        channel_members {
          items {
            user_id
            user_fname
            user_lname
            created_at
            member_id
          }
        }
      }
    }
  }
`

export const subscribeToChannelActivities = gql`
  subscription subscribeToChannelActivities($channelId: ID!, $userInput: MemberViewedQuery!) {
    onSavedChannelActivity(channel_id: $channelId) {
      channel_activity_id
      channel_id
      activity_id
      activity_created_at
      has_member_viewed(input: $userInput)
      activity {
        activity_id
        activity_base_type
        activity_commented
        activity_completion_desc
        activity_count_comments
        activity_count_likes
        activity_count_pins
        activity_creator
        activity_desc
        activity_escalation_owner
        activity_is_hidden
        activity_is_version
        activity_notification_heading
        activity_owner
        activity_shared
        activity_status
        activity_time_completed
        activity_time_created
        activity_time_due
        activity_type
        activity_type_id
        company_id
        file_ids
        parent_activity_id
        parent_activity {
          activity_id
          activity_creator
          oCreator
          activity_notification_heading
          activity_time_created
          file_ids
        }
        quote_id
        target_id
        target_type
        oCreator
        oOwner
        oTarget
        activity_mentions {
          mention_type
          mention_type_id
          mention_text
          mention_start_index
        }
      }
    }
  }
`

export default {
  getActivity,
  getActivitiesByTarget,
  getActivitiesByCreator,
  getActivitiesByQuote,
  getChannelActivities,
  getNotificationsByUser,
  removeNotification,
  postActivity,
  postChannelUserOnline,
  postMemberActivity,
  removeChannelUserOnline,
  subscribeToChannelActivities,
  subscribeToNotifications,
  getFilePreSignedPost
}
