import { client } from '../apollo-config'
import {
  upsertItemReviewalMutation,
  getItemReviewalQuery,
  getItemReviewalsByChangeOrderQuery
} from '../api-calls/itemReviewal'

const handleError = async (asyncFn) => {
  try {
    return await asyncFn()
  } catch (error) {
    console.log('error: ', error)
    return null
  }
}

export async function upsertItemReviewal({ itemReviewal, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.mutate({
      mutation: upsertItemReviewalMutation,
      variables: {
        itemReviewal,
        accessToken
      }
    })
    return data.upsertItemReviewal
  })
}

export async function getItemReviewal({ itemId, changeOrderId, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.query({
      query: getItemReviewalQuery,
      variables: {
        itemId,
        changeOrderId,
        accessToken
      }
    })
    return data.getItemReviewal
  })
}

export async function getItemReviewalsByChangeOrder({ changeOrderId, accessToken }) {
  const apolloClient = client()
  return handleError(async () => {
    const { data } = await apolloClient.query({
      query: getItemReviewalsByChangeOrderQuery,
      variables: {
        changeOrderId,
        accessToken
      }
    })
    return data.getItemReviewalsByChangeOrder
  })
}

export default {
  upsertItemReviewal,
  getItemReviewal,
  getItemReviewalsByChangeOrder
}
