import { gql } from '@apollo/client/core'

export const subscribeToChannelMembers = gql`
  subscription onSavedMember($userId: ID!) {
    onSavedMember(user_id: $userId) {
      channel_id
      member_id
      user_id
      created_at
      member_status
      member_archived
      channel {
        channel_id
        parent_channel_id
        channel_name
        channel_type
        channel_type_id
        created_at
        company_id
        client_user_id
        client_fname
        client_lname
        channel_status
        channel_members {
          items {
            user_id
            user_fname
            user_lname
            created_at
            member_id
            user_channel_mute
            user_is_admin
          }
        }
        latest_channel_activity {
          channel_activity_id
          activity_created_at
          activity {
            activity_id
            activity_creator
            activity_time_created
            activity_completion_desc
            activity_owner
            activity_notification_heading
            target_id
            target_type
            activity_mentions {
              mention_type
              mention_type_id
              mention_text
              mention_start_index
            }
          }
        }
      }
    }
  }
`

export const getChannel = gql`
  query getChannel($input: ChannelQueryInput!) {
    getChannel(input: $input) {
      channel_id
      parent_channel_id
      channel_name
      channel_type
      channel_type_id
      created_at
      company_id
      client_user_id
      client_fname
      client_lname
      channel_status
      channel_members {
        items {
          user_id
          user_fname
          user_lname
          created_at
          member_id
          user_channel_mute
          user_is_admin
        }
      }
      latest_channel_activity {
        channel_activity_id
        activity_created_at
        activity {
          activity_id
          activity_creator
          activity_time_created
          activity_completion_desc
          activity_owner
          activity_notification_heading
          target_id
          target_type
          activity_mentions {
            mention_type
            mention_type_id
            mention_text
            mention_start_index
          }
        }
      }
    }
  }
`

export const getChannelByType = gql`
  query getChannelByType($input: ChannelQueryByTypeInput!) {
    getChannelByType(input: $input) {
      channel_id
      parent_channel_id
      channel_name
      channel_type
      channel_type_id
      created_at
      company_id
      client_user_id
      client_fname
      client_lname
      channel_status
      channel_members {
        items {
          user_id
          user_fname
          user_lname
          created_at
          member_id
          user_channel_mute
          user_is_admin
        }
      }
      latest_channel_activity {
        channel_activity_id
        activity_created_at
        activity {
          activity_id
          activity_creator
          activity_time_created
          activity_completion_desc
          activity_owner
          activity_notification_heading
          target_id
          target_type
          activity_mentions {
            mention_type
            mention_type_id
            mention_text
            mention_start_index
          }
        }
      }
    }
  }
`

export const getChannelsByParent = gql`
  query getChannelsByParent($input: ChannelQueryByParentInput!) {
    getChannelsByParent(input: $input) {
      items {
        channel_id
        parent_channel_id
        channel_name
        channel_type
        channel_type_id
        created_at
        company_id
        client_user_id
        client_fname
        client_lname
        channel_status
        channel_members {
          items {
            user_id
            user_fname
            user_lname
            created_at
            member_id
            user_channel_mute
            user_is_admin
          }
        }
        latest_channel_activity {
          channel_activity_id
          activity_created_at
          activity {
            activity_id
            activity_creator
            activity_time_created
            activity_completion_desc
            activity_owner
            activity_notification_heading
            target_id
            target_type
            activity_mentions {
              mention_type
              mention_type_id
              mention_text
              mention_start_index
            }
          }
        }
      }
      nextToken
    }
  }
`

export const getChannelsByUserId = gql`
  query getUserChannels($input: MemberQueryByUserInput!) {
    getUserChannels(input: $input) {
      items {
        channel_id
        user_id
        member_id
        created_at
        member_status
        member_archived
        channel {
          channel_id
          parent_channel_id
          channel_name
          channel_type
          channel_type_id
          created_at
          company_id
          client_user_id
          client_fname
          client_lname
          channel_status
          channel_members {
            items {
              user_id
              user_fname
              user_lname
              created_at
              member_id
              user_channel_mute
              user_is_admin
            }
          }
          latest_channel_activity {
            channel_activity_id
            activity_created_at
            activity {
              activity_id
              activity_creator
              activity_time_created
              activity_completion_desc
              activity_owner
              activity_notification_heading
              target_id
              target_type
              activity_mentions {
                mention_type
                mention_type_id
                mention_text
                mention_start_index
              }
            }
          }
        }
      }
      nextToken
    }
  }
`

export const postChannel = gql`
  mutation postChannel($input: ChannelMutationInput!) {
    postChannel(input: $input) {
      channel_id
      parent_channel_id
      channel_type
      channel_type_id
      channel_name
      created_at
      company_id
      client_user_id
      client_fname
      client_lname
      channel_status
    }
  }
`

export const updateChannel = gql`
  mutation updateChannel($input: ChannelUpdateMutationInput!) {
    updateChannel(input: $input) {
      channel_id
      parent_channel_id
      channel_type
      channel_type_id
      channel_name
      company_id
      client_user_id
      client_fname
      client_lname
      channel_status
    }
  }
`

export const getChannelMember = gql`
  query getChannelMember($input: MemberQueryInput!) {
    getChannelMember(input: $input) {
      member_id
      user_id
      channel_id
      created_at
      user_channel_mute
    }
  }
`

export const postChannelMember = gql`
  mutation postMember($input: MemberMutationInput!) {
    postMember(input: $input) {
      channel_id
      member_id
      user_id
      user_fname
      user_lname
      created_at
      user_channel_mute
    }
  }
`

export const updateChannelMember = gql`
  mutation updateMember($input: MemberUpdateMutationInput!) {
    updateMember(input: $input) {
      user_id
      channel_id
      user_channel_mute
      member_status
      member_archived
    }
  }
`

export const postChannelMembers = gql`
  mutation postMembers($input: [MemberMutationInput]!) {
    postMembers(input: $input) {
      channel_id
      member_id
      user_id
      user_fname
      user_lname
      created_at
      member_status
      member_archived
    }
  }
`

export const removeChannelMembers = gql`
  mutation removeMembers($input: [MemberMutationInput]!) {
    removeMembers(input: $input) {
      channel_id
      member_id
      user_id
      user_fname
      user_lname
      created_at
      member_status
      member_archived
    }
  }
`

export default {
  subscribeToChannelMembers,
  getChannel,
  getChannelsByUserId,
  getChannelMember,
  postChannel,
  postChannelMember,
  postChannelMembers,
  removeChannelMembers
}
