import {
  ApolloClient,
  ApolloLink,
  ObservableQuery as Observable,
  createHttpLink,
  InMemoryCache
} from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { getMainDefinition } from '@apollo/client/utilities'
import { fetchAuthSession } from 'aws-amplify/auth'

const url = import.meta.env ? import.meta.env.VITE_APPSYNC_ENDPOINT : process.env.APPSYNC_ENDPOINT
const region = import.meta.env ? import.meta.env.VITE_AWS_REGION : process.env.AWS_REGION
const isOffline = import.meta.env ? import.meta.env.VITE_IS_OFFLINE : process.env.IS_OFFLINE

let jwtToken = null
let isRefreshing = false
let refreshTokenAttempts = 0
const maxTokenAttempts = 3

const setToken = (newToken) => {
  jwtToken = newToken
}

const idToken = async () => {
  if (isOffline) {
    return jwtToken
  }
  const { tokens } = await fetchAuthSession()
  return tokens.idToken.toString()
}

const accessToken = async () => {
  if (isOffline) {
    return jwtToken
  }
  const { tokens } = await fetchAuthSession()
  return tokens.accessToken.toString()
}

const getScope = () => JSON.parse(localStorage.getItem('scope'))

const getNewToken = async () => {
  const { tokens } = await fetchAuthSession()
  return tokens.idToken.toString()
}

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (
    graphQLErrors &&
    graphQLErrors.some((err) => {
      return (
        (err.extensions && err.extensions.code === 'UNAUTHENTICATED') ||
        (err.errorType &&
          (err.message === 'Token has expired.' ||
            err.message === 'UnauthorizedException: Invalid JWT token'))
      )
    })
  ) {
    if (!isRefreshing) {
      isRefreshing = true
      refreshTokenAttempts = 0
    }

    if (refreshTokenAttempts < maxTokenAttempts) {
      refreshTokenAttempts += 1

      fetchAuthSession({ forceRefresh: true }).then(() => {
        const oldHeaders = operation.getContext().headers
        const newToken = getNewToken()
        const headers = {
          ...oldHeaders,
          Authorization: newToken
        }
        operation.setContext({
          headers
        })
      })

      return new Observable((observer) => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        }
        forward(operation).subscribe(subscriber)
      })
    }
    throw new Error('Max token refresh attempts reached')
  }

  return forward(operation)
})

const createLinkFrom = (auth, scope) => {
  const httpLink = createHttpLink({
    uri: url,
    fetch
  })

  const authLink = createAuthLink({ url, region, auth })

  const scopeLink = new ApolloLink((operation, forward) => {
    const headers = {
      ...operation.getContext().headers,
      'x-bolster-scope': JSON.stringify(scope)
    }
    operation.setContext({
      headers
    })
    return forward(operation)
  })

  const isSubscription = ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  }

  return ApolloLink.split(
    isSubscription,
    createSubscriptionHandshakeLink(
      { url, region, auth },
      errorLink.concat(authLink).concat(httpLink)
    ),
    errorLink.concat(scopeLink).concat(authLink).concat(httpLink)
  )
}

const client = () => {
  const auth = {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: () => idToken()
  }
  const scope = getScope()

  const link = createLinkFrom(auth, scope)

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache'
      },
      mutate: {
        fetchPolicy: 'no-cache'
      }
    }
  })
}

const apiKeyClient = () => {
  const auth = {
    type: 'API_KEY',
    apiKey: import.meta.env.VITE_APPSYNC_API_KEY
  }
  const scope = getScope()

  const link = createLinkFrom(auth, scope)

  return new ApolloClient({
    link,
    cache: new InMemoryCache()
  })
}

export { client, apiKeyClient, setToken, accessToken, idToken }
